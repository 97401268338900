<script>
export default {
    name: "section-hills-stars"
}
</script>

<template>
    <div class="stars"></div>
    <div class="twinkling"></div>
    <div class="hills"></div>
</template>

<style scoped lang="scss">
@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-webkit-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-moz-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-ms-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

.stars, .twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
}

.stars {
    background: #372525 url('@/assets/images/stars.webp') repeat top center;
    z-index: 0;
}

.twinkling {
    background: transparent url(@/assets/images/twinkling.webp) repeat top center;
    z-index: 1;
    animation: move-twink-back 200s linear infinite;
    &:before {
        content: "";
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1a100f+0,53393b+44,53393b+100&1+0,1+44,0+100 */
        background: -moz-linear-gradient(top, rgba(26, 16, 15, 1) 0%, rgba(83, 57, 59, 1) 44%, rgba(83, 57, 59, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(26, 16, 15, 1) 0%, rgba(83, 57, 59, 1) 44%, rgba(83, 57, 59, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(26, 16, 15, 1) 0%, rgba(83, 57, 59, 1) 44%, rgba(83, 57, 59, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a100f', endColorstr='#0053393b', GradientType=0); /* IE6-9 */
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
}

.hills {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: url(@/assets/images/hills.webp) 50% 0 / cover;
    z-index: 2;
}
</style>
