<script>
export default {
    name: "section-navigation",
    methods: {
        toggle() {
            document.documentElement.classList.toggle('menu-active');
            let menu = document.querySelector('.nav-trigger');
            menu.style.display = 'none';
        }
    }
}
</script>

<template>
    <div class="nav-trigger position-absolute" @click="toggle">
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<style scoped lang="scss">

</style>
