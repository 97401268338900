
<script>
export default {
    name: "section-contact",
    mounted() {
        const script = document.createElement("script");
        script.src =
            "https://static.elfsight.com/platform/platform.js";
        document.body.appendChild(script);
    },
}
</script>

<template>
    <div class="elfsight-app-23d5fd15-8f99-47b9-a759-3d44e1b84129"></div>
</template>

<style scoped lang="scss">

</style>
