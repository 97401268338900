import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';  // Import vue-meta equivalent
import VueGtag from "vue-gtag";

const app = createApp(App);
const head = createHead();  // Create a head instance
app.use(VueGtag, {
	config: { id: "G-ZGPB2984L6" }
});
app.use(router);   // Use router with the Vue app instance
app.use(head);  // Use the head instance
app.mount('#app');
