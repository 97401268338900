import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/page-main.vue';
import Page from '@/components/page-default.vue';
import NotFound from '@/components/page-404.vue';

const routes = [
	{ path: '/', name: 'home', component: Home },  // Home route
	{ path: '/404', name: 'NotFound', component: NotFound },
	{ path: '/:slug', name: 'page', component: Page },  // Dynamic route for pages
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
