<script>
import router from "@/router";

export default {
    name: "section-sidebar",
    data() {
        return {
            title: process.env.VUE_APP_SITE_TITLE,
            position: process.env.VUE_APP_JOB_POSITION
        }
    },
    methods: {
        toggle() {
            document.documentElement.classList.remove('menu-active');
            document.documentElement.classList.remove('content-active');
            let menu = document.querySelector('.nav-trigger');
            menu.style.display = 'block';
            router.push({path: '/'})
        }
    }
}
</script>

<template>
    <section class="sidebar pt-40 pl-40 pr-40">
        <div class="close-section sidebar" @click="toggle">
            <span></span>
            <span></span>
        </div>
        <h1 class="text-center text-regular pb-30 mb-0 text-size-large">{{ title }}</h1>
        <figure class="text-center mt-30 mb-40"><img src="../assets/images/miroslavbeslin.webp" :alt="title" width="350" height="350"/>
        </figure>
        <h2 class="text-size-normal mt-0 mb-40">Current position: <span class="font-weight-bold display-block mt-5">{{ position }}</span>
        </h2>
        <h3 class="text-size-normal mb-25 text-uppercase">Check out my work</h3>
        <nav>
            <ul class="m-0 p-0 list-unstyled">
                <li>
                    <RouterLink to="/web" class="text-uppercase text-size-medium">Web development</RouterLink>
                </li>
                <li>
                    <RouterLink to="/logo" class="text-uppercase text-size-medium">Logo design</RouterLink>
                </li>
                <li>
                    <RouterLink to="/tshirt" class="text-uppercase text-size-medium">T-Shirt design</RouterLink>
                </li>
            </ul>
        </nav>
        <footer>
            <ul class="social-media list-inline f0 text-center mb-25">
                <li class="list-inline-item mr-15">
                    <a href="https://instagram.com/beeroslav" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="19" height="19">
                            <path
                                fill="#FFF"
                                d="M13.1 0H5.9C2.7 0 0 2.7 0 5.9V13c0 3.3 2.7 6 5.9 6H13c3.3 0 5.9-2.7 5.9-5.9V5.9C19 2.7 16.3 0 13.1 0zm4.1 13.1c0 2.3-1.9 4.2-4.2 4.2H5.9c-2.3 0-4.2-1.9-4.2-4.2V5.9c0-2.3 1.9-4.2 4.2-4.2H13c2.3 0 4.2 1.9 4.2 4.2v7.2zM9.5 4.8c-2.6 0-4.8 2.1-4.8 4.8s2.1 4.8 4.8 4.8 4.8-2.1 4.8-4.8-2.2-4.8-4.8-4.8zm0 7.7c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3c0 1.6-1.4 3-3 3zm5.1-8.7c.3 0 .6.3.6.6s-.2.6-.6.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6z"
                            />
                        </svg>
                    </a>
                </li>
                <li class="list-inline-item mr-15">
                    <a href="https://facebook.com/beslin" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="19" height="19">
                            <path
                                fill="#FFF"
                                d="M16.6 0H2.4C1.1 0 0 1.1 0 2.4v14.3C0 17.9 1.1 19 2.4 19h7.1v-6.5H7.1v-3h2.4V7.1c0-2 1.6-3.6 3.6-3.6h2.4v3h-1.2c-.7 0-1.2-.1-1.2.6v2.4h3l-1.2 3h-1.8V19h3.6c1.3 0 2.4-1.1 2.4-2.4V2.4C19 1.1 17.9 0 16.6 0z"
                            />
                        </svg>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a href="https://linkedin.com/in/miroslavbeslin" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="19" height="19">
                            <path
                                fill="#FFF"
                                d="M0 5.9h4.2V19H0V5.9zm15.8.2H15.5c-.2 0-.5-.1-.8-.1-2.5 0-4 1.8-4.6 2.5V5.9H5.9V19h4.2v-7.1s3.2-4.5 4.6-1.2V19H19v-8.8c0-2-1.4-3.6-3.2-4.1zM0 2.1c0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1S3.2 0 2.1 0 0 .9 0 2.1z"
                            />
                        </svg>
                    </a>
                </li>
            </ul>
            <RouterLink to="/contact" class="btn btn-white btn-quote text-uppercase text-size-medium">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
                    <path
                        d="M13.4 17.4c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0l6 2.6c.1.1.3 0 .4-.1.1-.1.1-.3.1-.4l-2.6-6L4.2.2c-.3-.3-.8-.3-1.1 0L.2 3.1c-.3.3-.3.8 0 1.1l13.2 13.2c0-.1 0-.1 0 0 0-.1 0 0 0 0zM.8 3.7c0-.1 0-.1 0 0l.6-.7 12.4 12.4c-.1.1-.3.1-.4.2-.1.1-.2.2-.2.4L.8 3.7zm13.1 13c-.1-.2-.1-.4 0-.6.2-.2.4-.2.6 0 .1.1.4.1.5 0 .1-.1.1-.4 0-.5-.2-.2-.2-.4 0-.6.2-.2.4-.2.6 0 .1.1.4.1.5 0 .1-.1.1-.4 0-.5-.2-.2-.2-.4 0-.6.2-.2.4-.2.6 0l.9 2.1-1.7 1.7-2-1zm1-2.4c-.1.1-.3.1-.4.2-.1.1-.2.2-.2.4L1.9 2.5l.6-.6 12.4 12.4zM3.6.8L16 13.1c-.1.1-.3.1-.4.2-.1.1-.2.2-.2.4L3 1.4l.6-.6zM16.7 18l1.3-1.3.9 2.2-2.2-.9zm2.2.9"
                    />
                </svg>
                Request a quote
            </RouterLink>
        </footer>
    </section>
</template>

<style scoped lang="scss">

</style>
