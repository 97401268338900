<template>
    <section class="content-container logos pb-40 pr-40 pt-40 pl-40">
        <div class="close-section" @click="toggle">
            <span></span>
            <span></span>
        </div>
        <div v-if="page">
            <h2 class="mb-40 text-size-large text-uppercase content-title">{{ page.title.rendered }}</h2>
            <div class="content" v-if="page.title.rendered === 'Contact'">
                <section-contact/>
            </div>
            <div class="content" v-else>
                <div class="row" v-if="page.acf && page.acf.fields">
                    <div class="col-md-4" v-for="(single, key) in page.acf.fields" :key="key">
                        <img v-if="single.image" :src="single.image.url" :alt="single.image.alt">
                        <p class="info mb-40 mt-10">Client: <a v-if="single.link" :href="single.link"
                                                               target="_blank">{{ single.client }}</a><span
                            v-else>{{ single.client }}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <p v-else>Loading...</p>
    </section>
</template>

<script>
import {getPageBySlug} from "@/services/api";
import router from "@/router";
import SectionContact from "@/components/section-contact.vue";
import { useHead } from '@vueuse/head';
import ogImage from "@/assets/images/fb.jpg";

export default {
    name: 'page-default',
    components: {SectionContact},
    methods: {
        toggle() {
            router.push({path: '/'})
        },
        async fetchPageContent() {
            this.page = await getPageBySlug(this.$route.params.slug);
            /*if (this.page) {
                this.$router.push({ name: 'NotFound'});
            }*/
            if (this.page) {
                this.title = this.page.title.rendered;
            }
        }
    },
    data() {
        return {
            page: null,
            title: ''
        };
    },
    async created() {
        let menu = document.querySelector('.nav-trigger');
        menu.style.display = 'none';
        document.documentElement.classList.add('menu-active');
        document.documentElement.classList.add('content-active');
    },
    async updated() {
        useHead({
            htmlAttrs: {
                lang: 'en',
            },
            title: `${this.title} - Miroslav Bešlin`,
            meta: [
                { name: 'description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                { property: 'og:title', content: `${this.title} - ${process.env.VUE_APP_SITE_TITLE}` },
                { property: 'og:site_name', content: `${process.env.VUE_APP_SITE_TITLE} - ${process.env.VUE_APP_SITE_TAGLINE}` },
                { property: 'og:type', content: 'website' },
                { property: 'og:url', content: `${process.env.VUE_APP_SITE_URL}/${this.$route.params.slug}` },
                { property: 'og:description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                { property: 'og:image', content: ogImage }
            ],
        });
    },
    async unmounted() {
        document.documentElement.classList.remove('content-active');
    },
    watch: {
        '$route.params.slug': {
            immediate: true,
            async handler() {
                await this.fetchPageContent();  // Fetch content when route changes
            },
        },
        'page': {
            immediate: false,
            async handler(newVal) {
                if (!newVal) {
                    this.$router.push({ name: 'NotFound'});
                }
            }
        }
    },
};
</script>

<style scoped>
/* Optional styling */
</style>
