import axios from 'axios';

// Replace with your WordPress URL
const API_URL = `${process.env.VUE_APP_API_URL}/wp-json/wp/v2`;

export const getPosts = async () => {
	const response = await axios.get(`${API_URL}/posts`);
	return response.data;
};

export const getPage = async (id) => {
	const response = await axios.get(`${API_URL}/pages/${id}?acf_format=standard`);
	return response.data;
}

export const getPageBySlug = async (slug) => {
	const response = await axios.get(`${API_URL}/pages?slug=${slug}&acf_format=standard`);
	return response.data[0];

};


