<template>
    <page-loader :show="loader"/>
    <section-navigation/>
    <section-sidebar/>
    <main>
        <router-view/>
    </main>
    <section-hills-stars/>
</template>

<script>
import PageLoader from "@/components/page-loader.vue";
import SectionHillsStars from "@/components/section-hills-stars.vue";
import SectionSidebar from "@/components/section-sidebar.vue";
import SectionNavigation from "@/components/section-navigation.vue";
import router from "@/router";

export default {
    name: 'App',
    components: {
        SectionSidebar,
        SectionHillsStars,
        SectionNavigation,
        PageLoader,
    },
    data() {
      return {
          loader: true,
          path: this.$route.params.slug
      }
    },
    mounted() {
        // handle esc button
        document.addEventListener('keyup', function (e) {
            const html = document.documentElement;

            if (e.keyCode === 27) {
                if (html.classList.contains('content-active')) {
                    html.classList.remove('content-active');
                    router.push({path: '/'})
                } else if (html.classList.contains('menu-active')) {
                    document.querySelector('.nav-trigger').style.display = 'block';
                    html.classList.remove('menu-active');
                }
            }
        });
        window.addEventListener('load', () => {
            this.loader = false;
        })
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/style'; // Import the main SCSS file
</style>
